//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-384:_3878,_4096,_7912,_2752,_5696,_9288,_5552,_1904;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-384')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-384', "_3878,_4096,_7912,_2752,_5696,_9288,_5552,_1904");
        }
      }catch (ex) {
        console.error(ex);
      }
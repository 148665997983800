
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('div', { 'className': 'one-fourth column medium-down--one-half medium-down--one-whole thumbnail quick-shop-style--popup product__details thumbnail-hover-enabled--false product-5403022727' }, _createElement('div', { 'className': 'product-wrap' }, _createElement('div', { 'className': 'relative product_image' }, _createElement('a', { 'href': this.url }, _createElement('div', { 'className': 'image__container' }, _createElement('div', { 'className': 'image-element__wrap' }, _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError,
                    'className': 'transition--blur-up lazyautosizes lazyloaded'
                }))))), _createElement('div', { 'className': 'price-ui-badges price-ui-badges--square' }, _createElement('div', {
                    'className': 'price-ui-badge price-ui-badge--loading',
                    'data-price-ui-badge': true
                }, (!this.out_of_stock || this.has_custom_availability_message) && this.on_sale ? _createElement('div', {
                    'className': 'price-ui-badge__sticker',
                    'key': '757'
                }, _createElement('span', {
                    'className': 'price-ui-badge__sticker-text',
                    'data-badge': true
                }, 'Sale')) : null, this.out_of_stock && !this.has_custom_availability_message ? _createElement('div', {
                    'className': 'price-ui-badge__sticker price-ui-badge__sticker--sold-out',
                    'key': '990'
                }, _createElement('span', {
                    'className': 'price-ui-badge__sticker-text',
                    'data-badge': true
                }, 'Sold out')) : null, this.aggressive ? _createElement('div', {
                    'className': 'ui-badge',
                    'key': '1243'
                }, _createElement('svg', {
                    'className': 'cm_icon cm_icon-exclamation',
                    'viewBox': '-1.12 -1.12 30.20 30.20'
                }, _createElement('path', { 'd': 'M13.983,0C6.261,0,0.001,6.259,0.001,13.979c0,7.724,6.26,13.984,13.982,13.984s13.98-6.261,13.98-13.984 C27.963,6.259,21.705,0,13.983,0z M13.983,26.531c-6.933,0-12.55-5.62-12.55-12.553c0-6.93,5.617-12.548,12.55-12.548 c6.931,0,12.549,5.618,12.549,12.548C26.531,20.911,20.913,26.531,13.983,26.531z' }), _createElement('polygon', { 'points': '15.579,17.158 16.191,4.579 11.804,4.579 12.414,17.158' }), _createElement('path', { 'd': 'M13.998,18.546c-1.471,0-2.5,1.029-2.5,2.526c0,1.443,0.999,2.528,2.444,2.528h0.056c1.499,0,2.469-1.085,2.469-2.528 C16.441,19.575,15.468,18.546,13.998,18.546z' }))) : null))), _createElement('div', { 'className': 'product-info__caption' }, _createElement('div', { 'className': 'product-details' }, _createElement('div', { 'className': 'product-item__vendor' }, _createElement('a', mergeProps({
                    'className': 'link',
                    'href': this.vendor_url
                }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'title',
                    'itemprop': 'name'
                }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('span', { 'className': 'price sale' }, this.on_sale ? _createElement('span', {
                    'className': this.on_sale ? 'was_price' : '',
                    'key': '2367'
                }, _createElement('span', {
                    'className': 'money',
                    'data-currency-original': '>' + this.formatPrice(this.compare_at_price),
                    'data-currency-usd': '>' + this.formatPrice(this.compare_at_price),
                    'data-currency': 'USD'
                }, '\n            ', this.formatPrice(this.compare_at_price), '\n          ')) : null, _createElement('span', { 'className': 'current_price' }, _createElement('span', {
                    'className': 'money',
                    'data-currency-original': this.formatPrice(this.price),
                    'data-currency-usd': this.formatPrice(this.price),
                    'data-currency': 'USD'
                }, '\n            ', this.formatPrice(this.price), '\n          '))), this.sku ? _createElement('span', {
                    'className': 'product-item__sku',
                    'key': '3025'
                }, _createElement('span', { 'className': 'sku-text' }, 'SKU: ', _createElement('span', mergeProps({ 'className': 'sku-number' }, { dangerouslySetInnerHTML: { __html: this.sku } })))) : null, [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '32230'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null]), _createElement('div', { 'className': 'product-item__stock' }, this.availability_message ? _createElement('span', { 'key': '3675' }, this.availability_message) : null)));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You may also like')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []